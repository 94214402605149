import React,{ Component } from 'react';
//import 'babel-polyfill';
import './App.css';
//import Routes from './routes';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }



  componentDidMount=function() {

  };
  componentWillUnmount=function() {
    this.setState = ()=>{
    };
  };




  render() {
    return (
        <div className={'App'} style={{backgroundColor:'#FFF',}}>
		<div style={{fontSize:40,}}>帅</div>
          {/*<Routes auth={auth}/>*/}
        </div>
    );
  }
}

export default App;
